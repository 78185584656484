<template>
	<div class="productA" @click="selectProduct(product)">
		<div class="product-img">
			<div class="img">
				<img :src="product.cover" alt="">
			</div>
			<div class="hoverView">
				<img :src="product.cover" alt="">
				<div class="buttom">{{ isChinese() ? '立刻购买' : 'Buy at once' }}</div>
			</div>
		</div>
		<div class="product-sum">
			<div class="name">
				<span>{{ isChinese() ? product.name : product.nameEng }}</span>
				<span>¥{{ product.price }}</span>
			</div>
			<div class="bot">
				<span>{{ isChinese() ? product.classifyName : product.classifyNameEng }}</span>
				<span>{{ isChinese() ? '去购买' : 'Go to buy' }}</span>
			</div>
			<div class="colors" v-if="initialStyle == 'productSku'">
				<div v-for="(item, index) of 4" :key="index"></div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		components: {},
		props: {
			initialStyle: {
				type: String,
				default: 'search' //搜索页  index首页 productSku 商品分类页
			},
			product: {
				type: Object,
				default () {
					return {

					}
				}
			}
		},
		data() {
			return {};
		},
		methods: {
			selectProduct(item) {
				this.$router.push({
					path: 'productDetails',
					query: {
						id: item.id
					}
				})
			}
		}
	}
</script>
<style lang='scss'>
	.productA {
		//width: 280px;
		width: 23%;
		// height: 380px;
		height: 100%;
		margin: 0px 10px 50px 9px;

		&:nth-child(4n) {
			margin-right: 0px;
		}

		.product-img {

			//width: 280px;
			// height: 280px;
			width: 100%;
			height: 100%;
			background: #ECECEC;
			position: relative;

			.img {
				position: absolute;
				height: 100%;
				width: 100%;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.hoverView {
				position: relative;
				opacity: 0;
				height: 100%;

				img {
					width: 100%;
					//height: 280px;
					height: 100%;
				}

				.buttom {

					// width: 188px;
					width: 168px;
					padding: 8px 0px;
					background: #FFFFFF;
					border: 1px solid #333333;
					position: absolute;
					left: 50%;
					margin-left: -94px;
					bottom: 30px;
					font-size: 14px;
					text-align: center;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					cursor: pointer
				}

				.buttom:hover {
					background: #000;
					color: #fff;
					transition: background 1s ease .1s, color 1s ease .1s;
				}
			}
		}


		.product-img:hover {
			.hoverView {
				// display: block;
				opacity: 1;
				transition: opacity 1s ease .1s;
			}

			.img {
				// display: none;
				opacity: 0;
				transition: opacity 1s ease .1s;
			}
		}

		.product-sum {
			margin-top: 10px;

			div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				line-height: 45px;
			}

			.name {
				span {
					font-size: 17px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.bot>span {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
			}

			.colors {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				div {
					width: 32px;
					height: 32px;
					background: #EB6036;
					margin-right: 10px;
				}
			}
		}
	}
</style>