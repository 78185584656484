<template>
    <div>
        <div class="swiper-container"  style="height:700px;">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) of swiperList" :key="index">
                    <img :src="item.picture" style="width: 100%;" alt="" mode="widthFix">
                </div>
            </div>
        </div>
        <div style="height: 51px;background-color: #fff;"></div>
        <div class="classes">
            <div class="sort">
                <!-- @visible-change="e=> triggerClick" -->
                <el-dropdown trigger="click"  @command="triggerClick" >
                    <span class="el-dropdown-link">
                       {{ sort | returSort(isChinese()) }} <i class="el-icon--right" :class="!dropdownActive ? 'el-icon-caret-bottom':'el-icon-caret-top'"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="false" icon="el-icon-top" >{{ isChinese() ? '升序':'Ascending order' }}</el-dropdown-item>
                        <el-dropdown-item :command="true" icon="el-icon-bottom">{{ isChinese() ? '降序':'Grade down' }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="productSku flex_between">
                <div class="strSku">
                    <div class="sku">
                        <!-- <div class="h2">类别</div>
                        <div class="h3">全部（113）</div>
                        <div class="arr">
                            <div class="text">
                                外套（40）
                            </div>
                            <div class="text">
                                清仓处理：上衣（25）
                            </div>
                            <div class="text">
                                清仓处理：裤子（30）
                            </div>
                            <div class="text">
                                清仓处理：配饰（18）
                            </div>
                        </div> -->
                        <div class="h2">{{ isChinese() ? '筛选':'Screen' }}</div>
                        <div v-for="(item,index) of filterList" :key="index">
                            <div v-if="item.name == '颜色'">
                                <div class="h3">{{isChinese() ? item.name:item.nameEng}}</div>
                                <div class="arr">
                                    <div class="colors dis">
                                        <div v-for="(items, indexs) of item.specParamList"  :key="indexs"  
                                                @click="selecetSku(index, indexs, items)">
                                            <img  :class="skuIndex[index] == indexs ? 'active' : ''" 
                                                v-show="items.picture" :src="items.picture" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="h3">{{isChinese() ? item.name:item.nameEng}}</div>
                                <div class="arr">
                                    <div class="checkbox dis" @click="selecetSku(index, indexs, items)" 
                                        v-for="(items, indexs) of item.specParamList" :key="indexs">
                                        <div :class="skuIndex[index] == indexs ? 'active' : ''"></div> {{ isChinese() ? items.name : items.nameEng }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list flex_between">
                    <!-- initialStyle="productSku" -->
                    <productList initialStyle="index"   v-for="(item, index) of productList" :product="item" :key="index"></productList>
                </div>
                
            </div>
           
        </div>
        <div class="pagination">
            <div class="pages" v-if="total!=0">
                <div class="dis">
                    <!--  -->
                    <i class="el-icon-arrow-left" @click="clickLeft" style="font-size: 30px;"
                        :style="{ color: pageNum == 1 ? '#999' : '#000' }"></i>
                    <div>
                        {{ pageNum }} / {{ Math.ceil(total / 10) }}
                    </div>
                    <i class="el-icon-arrow-right" @click="clickRigth" style="font-size: 30px;"
                        :style="{ color: pageNum > 1 ? '#000' : '#999' }"></i>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
 import productList from '@/components/productList.vue'
 import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import $bus from '@/util/bus.js'
import {listCarousel,listGoodsByFilterField,listFilterField} from '@/api/main.js'
export default {
    components: { productList },
    data(){
        return {

            dropdownActive:false,
            pageNum:1,
            swiperList:[],
            total:0,
            goodsClassifyId:'',//分来id
            filterList:[],
            skuIndex: [],
            kety:[],
            productList:[],
            sort:true,
            mySwiper:null
        }
    },
    mounted() {
        this.goodsClassifyId = this.$route.query.id
       
        this.getlistCarousel();//获取轮播
        this.listFilterField(this.goodsClassifyId)//根据分类查询筛选条件
        $bus.$on('productSku',this.updataFilterList)
    },
    filters:{
        returSort(val,isChinese){
            if(isChinese){

                return val ? '排序方式：降序':'排序方式：升序'
            }else{
                return val ? 'Sort order:Grade down':'Sort order：Ascending order'
            }
        }
    },
    methods:{
        updataFilterList(e){
            this.pageNum = 1;
            this.productList = []
            this.kety = [];
            this.skuIndex = []
            this.filterList = [];
            this.listFilterField(e.id)
        },
        listFilterField(goodsClassifyId){//根据分类查询筛选条件
            listFilterField({
                goodsClassifyId
            }).then(res=>{
                if(res.code != 0) this.showMessage(res)
                this.filterList = res.data;
                res.data.forEach((item) => {
                    this.skuIndex.push(0)
                    this.kety.push(item.specParamList[0].id)
                })
                if(this.kety.length!=0){
                    this.listGoodsByFilterField();
                }
            })
        },
        listGoodsByFilterField(){//根据分类查询商品

            listGoodsByFilterField({
                key:this.kety.join(','),
                pageNum:this.pageNum,
                pageSize:10,
                sort:this.sort,//   降序true，升序false
            }).then(res=>{
                if(res.code != 0) this.showMessage(res)
                this.total = res.data.total
                // this.productList.push(...res.data.rows)
				this.productList = res.data.rows
            })
        },
        getlistCarousel(){//轮播
            listCarousel({}).then(res=>{
                if(res.code != 0) this.showMessage(res)
                this.swiperList = res.data
               
                let time = setTimeout(()=>{
                    clearTimeout(time)
                    this.initSwiper()
                },0)
            })
        },
        //选择规格
        selecetSku(index, indexs, item) {
            this.skuIndex[index] = indexs
            this.kety[index] = item.id;
            this.pageNum = 1;
            this.productList = [];
            this.listGoodsByFilterField()
        },
        triggerClick(e){
           // this.dropdownActive=e
            this.sort = e
            listGoodsByFilterField
            console.log(e)
            this.pageNum = 1;
            this.productList = [];
            this.listGoodsByFilterField()
        },
        clickLeft() {//上一页
            if (this.pageNum <= 1) return;
            this.pageNum--;
            this.listGoodsByFilterField();
        },
        clickRigth() {//下一页
            if (this.pageNum >= Math.ceil(this.total / 10)) return;
            this.pageNum++;
            this.listGoodsByFilterField();
        },
        initSwiper(){
           this.mySwiper =  new Swiper(".swiper-container", {
                direction: "horizontal", // 垂直切换选项
                //mousewheel: true, //滚轮
                autoplay: {
                    //自动开始
                    delay: 3500, //时间间隔
                    disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                loop: true, // 循环模式选项
                // 如果需要分页器
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, // 分页器可以点击
                },
            
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            
                // 如果需要滚动条
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
               
            });
            this.mySwiper.on('click', () => {
                if(this.swiperList[ this.mySwiper.realIndex].link){
                    window.open(this.swiperList[ this.mySwiper.realIndex].link);
                }
            });
        }
    },
    beforeDestroy(){
        $bus.$off('search')
    }
  }
</script>
<style lang="scss">
    
  .classes{
    padding: 0px 110px;
    background: #F9F9F9;
     
    .el-dropdown-link{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
    }
    .sort{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 30px 0px;
    }
    .productSku{
        align-items: flex-start;
        .list{
            width: 1399px;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        .strSku{
            width: 339px;
            .sku{
                .h2{
                    margin-top: 40px;
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2D2B2C;
                    line-height: 28px;
                    padding: 10px 0px;
                }
                .h3{
                    font-size: 22px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #777777;
                    line-height: 30px;
                    padding: 20px 0px;
                }
            }
            .arr{
                .checkbox{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    div{
                        margin: 10px;
                        width: 26px;
                        height: 26px;
                        border: 1px solid #CCCCCC;
                        margin-right: 10px;
                        font-size: 22px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                    &:hover{
                        color: #A1A1A1;
                        transition: color .2s ease .2s;
                    }
                    .active{
                        background: #2E2B2C;
                    }
                    
                }
                .text{
                    font-size: 22px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    padding: 10px 0px;
                    &:hover{
                        color: #A1A1A1;
                        transition: color .2s ease .2s;
                    }
                }
                .colors{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap:wrap;
                    img {
                        width: 48px;
                        height: 48px;
                        background: #B8A9CE;
                        margin-right: 10px;
                        border: 2px solid #E2E2E2;
                        cursor: pointer;
                    }
                    .active {
                        border: 2px solid #000;
                        transition: border 0.3s ease;
                    }
                    
                }
         }
        }
      
    }
  }
  .pagination{
    background-color: #FFFFFF;
    .pages{
        width: 1200px;
        padding: 40px 0px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        .dis{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            width: 200px;
        }
    }
}

</style>